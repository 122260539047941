import { Link } from "react-router";
import { useTranslation } from "react-i18next";
import {
	ListItemButton,
	ListItemIcon,
	ListItemText,
} from "@mui/material";
// cmp
import Svg from "../svg";
// services
import Constants from "../../services/constants";
import { icons } from "@local/theme";
// types
import type { RelativePathPrefix } from "../../types/misc";

type Props = Readonly<{
	relativePathPrefix: RelativePathPrefix;
	showFull?: boolean;
}>;

const DFF83 = (props: Props) => {
	const { t } = useTranslation();

	if (props.showFull) {
		return (
			<ListItemButton component={Link} to={`${props.relativePathPrefix}settings/${Constants.SettingsPageTypes.Steinel}`}>
				<ListItemIcon><Svg src="device-details/settings-icon.svg" size="medium" /></ListItemIcon>
				<ListItemText primary={t("clusters.DFF83.title")} secondary={t("clusters.DFF83.subTitle")} />
				<ListItemIcon><icons.ChevronRight /></ListItemIcon>
			</ListItemButton>
		);
	}

	return null;
};

DFF83.defaultProps = {
	showFull: false,
};

export default DFF83;
