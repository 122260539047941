import { Link } from "react-router";
import { useTranslation } from "react-i18next";
import {
	ListItemButton,
	ListItemIcon,
	ListItemText,
} from "@mui/material";
// services
import Constants from "../../services/constants";
import { icons } from "@local/theme";
// types
import type { RelativePathPrefix } from "../../types/misc";

type Props = Readonly<{
	relativePathPrefix: RelativePathPrefix;
}>;

const DFC00 = (props: Props) => {
	const { t } = useTranslation();

	return (
		<ListItemButton component={Link} to={`${props.relativePathPrefix}settings/${Constants.SettingsPageTypes.Sercomm}`}>
			<ListItemText primary={t("clusters.DFC00.advancedSettings")} />
			<ListItemIcon><icons.ChevronRight /></ListItemIcon>
		</ListItemButton>
	);
};

export default DFC00;
