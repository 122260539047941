import { Link } from "react-router";
import { Capacitor } from "@capacitor/core";
import { useTranslation } from "react-i18next";
import {
	Divider,
	ListItem,
	ListItemButton,
	ListItemIcon,
	ListItemText,
	Alert,
} from "@mui/material";
// cmp
import PhoneLocationSettings from "./PhoneLocationSettings";
import Svg from "../svg";
// hooks
import useDeviceInfo from "../../hooks/useDeviceInfo";
// contexts
import { useGateway } from "../../context/GatewayContext";
// services
import ClusterConstants from "../../services/cluster-constants";
import Constants from "../../services/constants";
import { icons } from "@local/theme";
// types
import type { EpDevice } from "../../types/device";
import type { DeviceType } from "../../types/device-type";
import type { RelativePathPrefix } from "../../types/misc";

type Props = Readonly<{
	epDevice: EpDevice;
	deviceType: DeviceType<"FFAD">;
	relativePathPrefix: RelativePathPrefix;
	showFull?: boolean;
}>;

const DFFAD = (props: Props) => {
	const { t } = useTranslation();

	const gateway = useGateway();
	const { loading, deviceId } = useDeviceInfo();

	const cluster = props.epDevice.getClusterByCapAndClusterId(props.deviceType.cap, props.deviceType.clusterId);
	const phoneSatus = Constants.PhoneStatus.find((phoneSatus) => (phoneSatus.value === cluster?.[ClusterConstants.DFFAD.Attributes.PhoneStatus]));
	const trackLocationEnabled = cluster?.[ClusterConstants.DFFAD.Attributes.TrackLocation] ?? false;

	if (props.showFull) {
		const isOwner = gateway ? Boolean(gateway.rbac & Constants.Gateway.RBAC.Owner) : false;
		const geofenceDisabled = !gateway?.geofence_status;

		return (
			<>
				{geofenceDisabled &&
					<Alert severity="info" sx={{ margin: "16px 16px 0" }}>
						{t(isOwner ? "clusters.DFFAD.enableGeofenceHintOwner" : "clusters.DFFAD.enableGeofenceHintUser")}
					</Alert>
				}
				<PhoneLocationSettings
					disabled={geofenceDisabled}
					epDevice={props.epDevice}
					phoneModeValue={cluster?.[ClusterConstants.DFFAD.Attributes.PhoneMode]}
					trackLocationEnabled={trackLocationEnabled}
				/>
				<Divider />
				<ListItem>
					<ListItemIcon><Svg src="device-details/info.svg" /></ListItemIcon>
					<ListItemText primary={props.deviceType.getTitle()} />
					<ListItemText primary={trackLocationEnabled ? (phoneSatus ? t(phoneSatus.label) : "") : t("clusters.DFFAD.phoneStatusOff")} />
				</ListItem>
				{(Capacitor.isNativePlatform() && !loading && deviceId === props.epDevice.id) &&
					<>
						<Divider />
						<ListItemButton component={Link} to={`${props.relativePathPrefix}settings/${Constants.SettingsPageTypes.Geofence}`}>
							<ListItemIcon><Svg src="navigation/settings.svg" /></ListItemIcon>
							<ListItemText primary={t("clusters.DFFAD.advancedSettings")} />
							<ListItemIcon><icons.ChevronRight /></ListItemIcon>
						</ListItemButton>
					</>
				}
			</>
		);
	}
	return null;
};

DFFAD.defaultProps = {
	showFull: false,
};

export default DFFAD;
