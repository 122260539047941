import { useState, useEffect, useCallback } from "react";
import { useTranslation } from "react-i18next";
import {
	Paper,
	ListItemButton,
	ListItemText,
	ListItemIcon,
	Button,
} from "@mui/material";
// cmps
import DrawerDialog from "../DrawerDialog";
import PhoneTracking from "./PhoneTracking";
import PhoneModesChooser from "./PhoneModesChooser";
import Toast from "../Toast";
// contexts
import { useGateway } from "../../context/GatewayContext";
// hooks
import useSend from "../../hooks/useSend";
// services
import DeviceType from "../../services/device-type";
import Constants from "../../services/constants";
import ClusterConstants from "../../services/cluster-constants";
import { decimal2Hex } from "../../services/utils";
import { icons } from "@local/theme";
// types
import type { EpDevice } from "../../types/device";
import type { CmdSendActionCmd, CmdGatewayActionGeofenceDisableEnablePhone } from "../../types/message";
import type { PhoneMode } from "../../types/misc";

type Props = Readonly<{
	disabled: boolean;
	epDevice: EpDevice;
	phoneModeValue: PhoneMode | undefined;
	trackLocationEnabled: boolean;
}>;

const PhoneLocationSettings = ({ disabled, epDevice, phoneModeValue, trackLocationEnabled }: Props) => {
	const send = useSend();

	const { t } = useTranslation();

	const gateway = useGateway();

	const [showDialog, setShowDialog] = useState(false);
	const [showGenericErrorMsg, setShowGenericErrorMsg] = useState<number | undefined>(undefined);
	const [localTrackLocationEnabled, setLocalTrackLocationEnabled] = useState(trackLocationEnabled);

	useEffect(() => {
		setLocalTrackLocationEnabled(trackLocationEnabled);
	}, [trackLocationEnabled]);

	const handleTrackLocationToggle = useCallback((isInputChecked: boolean) => {
		setLocalTrackLocationEnabled(isInputChecked);

		const cmd = {
			action: "gatewayAction",
			module: "geofence",
			function: isInputChecked ? "enable_phone" : "disable_phone",
			params: [{
				id: epDevice.id,
			}],
			gatewayId: gateway?.id!,
		} as const satisfies CmdGatewayActionGeofenceDisableEnablePhone;
		send(cmd, (error, msg) => {
			if (!error && msg?.payload.status === "ok") {
				setShowGenericErrorMsg(undefined);
			} else {
				setLocalTrackLocationEnabled((prevLocalTrackLocationEnabled) => (!prevLocalTrackLocationEnabled));
				setShowGenericErrorMsg(Date.now());
			}
		});
	}, [gateway?.id, epDevice.id]);

	const handlePhoneModeChange = useCallback((phoneMode: PhoneMode) => {
		const cmd = {
			action: "sendActionCmd",
			gatewayId: epDevice.gwId,
			srcGw: epDevice.srcGw,
			deviceId: epDevice.id,
			endpoint: epDevice.epId,
			caps: DeviceType.DFFAD.cap,
			clusterId: DeviceType.DFFAD.clusterId,
			cmdId: ClusterConstants.DFFAD.CmdIds.ChangePhoneMode,
			value: decimal2Hex(phoneMode, 2),
		} as const satisfies CmdSendActionCmd;
		send(cmd, (error, msg) => {
			if (!error && msg?.payload.status === "ok") {
				setShowGenericErrorMsg(undefined);
			} else {
				setShowGenericErrorMsg(Date.now());
			}
		});
	}, [epDevice.gwId, epDevice.srcGw, epDevice.id, epDevice.epId]);

	const phoneModeLabel = Constants.PhoneModes.find((phoneMode) => (phoneMode.value === phoneModeValue))?.label;

	return (
		<>
			<ListItemButton disabled={disabled} onClick={() => (setShowDialog(true))}>
				<ListItemIcon><icons.Location /></ListItemIcon>
				<ListItemText primary={t("clusters.DFFAD.phoneModeTitle")} />
				<ListItemText
					primary={phoneModeLabel ? t(phoneModeLabel) : ""}
					primaryTypographyProps={{ sx: { color: "primary.main" } }}
				/>
			</ListItemButton>
			<DrawerDialog
				id="dlg-phone-location-mode"
				title={t("clusters.DFFAD.dialogTitle")}
				open={showDialog}
				onClose={() => (setShowDialog(false))}
				drawerActions={null}
				dialogActions={<Button className="btn-dlg-action-close" color="inherit" onClick={() => (setShowDialog(false))}>{t("dialog.close")}</Button>}
			>
				<Paper className="section">
					<PhoneTracking
						primary={t("clusters.DFFAD.trackLocation")}
						enabled={localTrackLocationEnabled}
						onToggle={handleTrackLocationToggle}
					/>
				</Paper>
				<PhoneModesChooser
					disabled={!localTrackLocationEnabled}
					phoneMode={phoneModeValue}
					onPhoneModeChange={handlePhoneModeChange}
				/>
			</DrawerDialog>
			<Toast
				autoHideDuration={6000}
				severity="error"
				open={showGenericErrorMsg}
				onClose={setShowGenericErrorMsg}
				message={t("toast.genericErrorMsg")}
			/>
		</>
	);
};

export default PhoneLocationSettings;
