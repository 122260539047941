import { memo } from "react";
import { ListItem, ListItemText } from "@mui/material";
// cmps
import CustomSwitch from "../custom-switch";
import ListItemContainer from "../ListItemContainer";

type Props = Readonly<{
	primary: string;
	secondary?: string;
	disabled?: boolean;
	enabled: boolean;
	onToggle: (checked: boolean) => void;
}>;

const PhoneTracking = ({ primary, secondary = undefined, disabled = false, enabled, onToggle }: Props) => (
	<ListItem disabled={disabled}>
		<ListItemText primary={primary} secondary={secondary} />
		<ListItemContainer>
			<CustomSwitch
				checked={enabled}
				disabled={disabled}
				onChange={onToggle}
			/>
		</ListItemContainer>
	</ListItem>
);

export default memo(PhoneTracking);
