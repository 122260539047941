import { ListItem, ListItemText } from "@mui/material";
// types
import type { EpDevice } from "../../types/device";
import type { DeviceType } from "../../types/device-type";

type Props = Readonly<{
	epDevice: EpDevice;
	deviceType: DeviceType<"FD05">;
	showFull?: boolean;
}>;

const DFD05 = (props: Props) => {
	const cluster = props.epDevice.getClusterByCapAndClusterId(props.deviceType.cap, props.deviceType.clusterId)!;

	if (props.showFull) {
		return (
			<ListItem>
				<ListItemText sx={{ minWidth: "fit-content" }} primary={props.deviceType.getTitle()} />
				<ListItemText sx={{ paddingLeft: "16px" }}>{props.deviceType.formatValue(cluster)}</ListItemText>
			</ListItem>
		);
	}
	return null;
};

DFD05.defaultProps = {
	showFull: false,
};

export default DFD05;
