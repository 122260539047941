import { useState, useEffect, useRef } from "react";
// services
import { getTimePartsFromDuration } from "../../services/utils";
// types
import type { CSSProperties } from "react";
// import type { IsoTimeStamp } from "../../types/misc";

type Props = Readonly<{
	dateTime: string; // TODO: IsoTimeStamp;
	hideHours?: boolean;
	style?: CSSProperties;
}>;

const getTimeDiff = (dateTime: string) => {
	const time = new Date(dateTime).getTime();
	return Number.isNaN(time) ? 0 : Math.max(0, time - Date.now());
};

const Timer = ({ dateTime, hideHours = false, style = undefined }: Props) => {
	const intervalIdRef = useRef<number | undefined>(undefined);

	const [timeDiff, setTimeDiff] = useState(getTimeDiff(dateTime));

	useEffect(() => {
		const _timeDiff = getTimeDiff(dateTime);
		setTimeDiff(_timeDiff);

		if (_timeDiff > 0) {
			intervalIdRef.current = window.setInterval(() => {
				setTimeDiff(getTimeDiff(dateTime));
			}, 1000);
		} else {
			window.clearInterval(intervalIdRef.current);
		}

		return () => {
			window.clearInterval(intervalIdRef.current);
		};
	}, [dateTime]);

	if (timeDiff > 0) {
		const { hours, minutes, seconds } = getTimePartsFromDuration(timeDiff);

		return (
			<span style={style}>
				{[hours, minutes, seconds]
					.filter((_digit, index) => (!hideHours || index > 0))
					.map((digit) => (digit.toString().padStart(2, "0")))
					.join(":")
				}
			</span>
		);
	}

	return null;
};

export default Timer;
