import { useState } from "react";
import { useTranslation } from "react-i18next";
import { ListItem, ListItemText } from "@mui/material";
// cmp
import ListItemContainer from "../ListItemContainer";
import CustomSwitch from "../custom-switch";
import Toast from "../Toast";
// hooks
import useSend from "../../hooks/useSend";
import useDynamicUpdateState from "../../hooks/useDynamicUpdateState";
// services
import Constants from "../../services/constants";
import ClusterConstants from "../../services/cluster-constants";
// types
import type { EpDevice } from "../../types/device";
import type { DeviceType } from "../../types/device-type";
import type { CmdSendGeneralCmdWrite } from "../../types/message";

type Props = Readonly<{
	epDevice: EpDevice;
	deviceType: DeviceType<"FF82">;
	showFull?: boolean;
}>;

const DFF82 = (props: Props) => {
	const send = useSend();

	const { t } = useTranslation();

	const cluster = props.epDevice.getClusterByCapAndClusterId(props.deviceType.cap, props.deviceType.clusterId)!;

	const [toggle, setToggle] = useDynamicUpdateState(Boolean(props.deviceType.formatValue(cluster)));
	const [showGenericErrorMsg, setShowGenericErrorMsg] = useState<number | undefined>(undefined);

	const handleToggle = (isInputChecked: boolean) => {
		setToggle(isInputChecked);

		const cmd = {
			action: "sendGeneralCmd",
			gatewayId: props.epDevice.gwId,
			srcGw: props.epDevice.srcGw,
			deviceId: props.epDevice.id,
			endpoint: props.epDevice.epId,
			caps: props.deviceType.cap,
			clusterId: props.deviceType.clusterId,
			cmdId: Constants.GeneralCmdIds.WriteAttribute,
			values: [{
				id: ClusterConstants.DFF82.Attributes.MaintenanceMode,
				datatype: Constants.DataType.Boolean,
				value: isInputChecked,
			}],
		} as const satisfies CmdSendGeneralCmdWrite<"FF82">;
		send(cmd, (error, msg) => {
			if (!error && msg?.payload.status === "ok") {
				setShowGenericErrorMsg(undefined);
			} else {
				setShowGenericErrorMsg(Date.now());
			}
		});
	};

	if (props.showFull) {
		return (
			<>
				<ListItem>
					<ListItemText
						primary={t("clusters.DFF82.title")}
						secondary={t("clusters.DFF82.subtitle", { context: props.epDevice.stringOverrideKey })}
					/>
					<ListItemContainer><CustomSwitch checked={toggle} onChange={handleToggle} /></ListItemContainer>
				</ListItem>
				<Toast
					autoHideDuration={6000}
					severity="error"
					open={showGenericErrorMsg}
					onClose={setShowGenericErrorMsg}
					message={t("toast.genericErrorMsg")}
				/>
			</>
		);
	}

	return (
		<>
			<CustomSwitch checked={toggle} onChange={handleToggle} />
			<Toast
				autoHideDuration={6000}
				severity="error"
				open={showGenericErrorMsg}
				onClose={setShowGenericErrorMsg}
				message={t("toast.genericErrorMsg")}
			/>
		</>
	);
};

DFF82.defaultProps = {
	showFull: false,
};

export default DFF82;
