import { useTranslation } from "react-i18next";
import { ListItem, ListItemText } from "@mui/material";
// services
import Constants from "../../services/constants";
import ClusterConstants from "../../services/cluster-constants";
// types
import type { EpDevice } from "../../types/device";
import type { DeviceType as DeviceTypeT } from "../../types/device-type";

type Props = Readonly<{
	epDevice: EpDevice;
	deviceType: DeviceTypeT;
}>;

const DFF9C = (props: Props) => {
	const { t } = useTranslation();

	const cluster = props.epDevice.getClusterByCapAndClusterId(props.deviceType.cap, props.deviceType.clusterId)!;

	const getValveActivityText = () => {
		switch (cluster[ClusterConstants.DFF9C.Attributes.ValveActivity]) {
			case Constants.ValveActivity.Closed:
				return t("clusters.DFF9C.closed");
			case Constants.ValveActivity.ManualWatering:
			case Constants.ValveActivity.ScheduledWatering:
				return t("clusters.DFF9C.open");
			default:
				return "";
		}
	};

	const getValveStatusText = () => {
		const isErrorState = Object.values(Constants.ValveStatus).includes(cluster[ClusterConstants.DFF9C.Attributes.ValveState]);
		return isErrorState ? t("clusters.DFF9C.errorText") : "";
	};

	const getDeviceBatteryStateText = () => {
		switch (cluster[ClusterConstants.DFF9C.Attributes.DeviceBatteryState]) {
			case Constants.DeviceBatteryStatus.Low:
				return t("clusters.DFF9C.batteryLow");
			case Constants.DeviceBatteryStatus.ReplaceNow:
				return t("clusters.DFF9C.replaceNow");
			default:
				return "";
		}
	};

	const messages = [
		getValveActivityText(),
		getValveStatusText(),
		getDeviceBatteryStateText(),
	].filter((message) => (message !== ""));

	return (
		<ListItem>
			<ListItemText primary={t("clusters.DFF9C.notification")} />
			<ListItemText primary={messages.join(" / ")} />
		</ListItem>
	);
};

export default DFF9C;
