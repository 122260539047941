import { memo } from "react";
import { useTranslation } from "react-i18next";
import {
	Paper,
	List,
	ListItemButton,
	ListItemText,
	ListItemIcon,
} from "@mui/material";
// services
import Constants from "../../services/constants";
import { icons } from "@local/theme";
// types
import type { PhoneMode } from "../../types/misc";

type Props = Readonly<{
	disabled?: boolean;
	phoneMode: PhoneMode | undefined;
	onPhoneModeChange: (phoneMode: PhoneMode) => void;
}>;

const PhoneModesChooser = ({ disabled = false, phoneMode, onPhoneModeChange }: Props) => {
	const { t } = useTranslation();

	return (
		<Paper className="section">
			<List disablePadding={true}>
				{Constants.PhoneModes.map(({ value, label }, index) => (
					<ListItemButton
						key={value}
						className="li-phone-mode"
						divider={index < Constants.PhoneModes.length - 1}
						disabled={disabled}
						data-phone-mode={value}
						sx={(value === phoneMode) ? { color: "primary.main" } : {}}
						onClick={() => (onPhoneModeChange(value))}
					>
						<ListItemText primary={t(label)} />
						{(value === phoneMode) && <ListItemIcon><icons.CheckCircle color="primary" /></ListItemIcon>}
					</ListItemButton>
				))}
			</List>
		</Paper>
	);
};

export default memo(PhoneModesChooser);
