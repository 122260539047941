import { Fragment } from "react";
import { Box } from "@mui/material";
// hooks
import useNumberFormatter from "../../hooks/useNumberFormatter";
// services
import Constants from "../../services/constants";
// types
import type { CSSProperties } from "react";

const FONT_SIZE = "1.5em";

const STYLE_DEFAULT = {} as const satisfies CSSProperties;

type Props = Readonly<{
	values: Readonly<Array<number>>;
	digits: number;
	unit: string;
	sameFontSize?: boolean;
	style?: CSSProperties;
}>;

const UnitDisplay = ({ values, digits, unit, sameFontSize = false, style = STYLE_DEFAULT }: Props) => {
	const numberFormatter0Digits = useNumberFormatter(0);
	const numberFormatter = useNumberFormatter(digits);

	const min = Math.min(...values);
	const max = Math.max(...values);
	const _values = (min === max) ? [min] as const : [min, max] as const;

	return (
		<Box component="span" sx={{ display: "inline-block", lineHeight: 1.2, whiteSpace: "nowrap" }} style={style}>
			{(Number.isNaN(min) || Number.isNaN(max))
				? <Box component="span" sx={sameFontSize ? undefined : {fontSize: FONT_SIZE}}>{Constants.InvalidValue}</Box>
				: _values.map((value, index) => (
					<Fragment key={value}>
						{(index > 0) && <Box component="span" sx={{ fontSize: sameFontSize ? undefined : FONT_SIZE, color: "text.secondary" }}> - </Box>}
						<Box component="span" sx={sameFontSize ? undefined : {fontSize: FONT_SIZE}}>{numberFormatter0Digits(Math.trunc(value))}</Box>
						{(digits > 0) && <span>{numberFormatter(Number(`0.${value.toString().split(".")[1] ?? 0}`)).substring(1)}</span>}
					</Fragment>
				))
			}
			<Box component="span" sx={{ color: "text.secondary" }}>{` ${unit}`}</Box>
		</Box>
	);
};

export default UnitDisplay;
