import { Fragment, useState } from "react";
import { useTranslation } from "react-i18next";
import {
	ListItem,
	ListItemText,
	DialogContentText,
	Button,
	Divider,
} from "@mui/material";
// cmp
import DrawerDialog from "../DrawerDialog";
import ListItemContainer from "../ListItemContainer";
import Toast from "../Toast";
// hooks
import useSend from "../../hooks/useSend";
import useDynamicUpdateState from "../../hooks/useDynamicUpdateState";
// services
import ClusterConstants from "../../services/cluster-constants";
import Constants from "../../services/constants";
// types
import type { ReactNode } from "react";
import type { ValueOf } from "type-fest";
import type { EpDevice } from "../../types/device";
import type { DeviceType } from "../../types/device-type";
import type { CmdSendActionCmd } from "../../types/message";

type SelfCheckStatus = ValueOf<typeof Constants.SelfCheckStatus>;

type Props = Readonly<{
	epDevice: EpDevice;
	deviceType: DeviceType<"FF9A">;
	showFull?: boolean;
}>;

const DFF9A = (props: Props) => {
	const send = useSend();

	const { t } = useTranslation();

	const cluster = props.epDevice.getClusterByCapAndClusterId(props.deviceType.cap, props.deviceType.clusterId);
	const lelPercentage = cluster?.[ClusterConstants.DFF9A.Attributes.LELPercentage] ?? null;

	const [showDialog, setShowDialog] = useState(false);
	const [showGenericErrorMsg, setShowGenericErrorMsg] = useState<number | undefined>(undefined);

	const [selfCheckStatus, setSelfCheckStatus] = useDynamicUpdateState(cluster?.[ClusterConstants.DFF9A.Attributes.SelfCheckingResult] ?? null);

	const handleDialogClose = () => {
		setShowDialog(false);
	};

	const getSelfCheckStatusText = (selfCheckStatus: SelfCheckStatus | null) => {
		switch (selfCheckStatus) {
			case Constants.SelfCheckStatus.CheckSuccess:
				return t("clusters.DFF9A.checkSuccess");
			case Constants.SelfCheckStatus.CheckFailure:
				return t("clusters.DFF9A.checkFailure");
			case Constants.SelfCheckStatus.Checking:
				return t("clusters.DFF9A.checking");
			case Constants.SelfCheckStatus.Unknown:
				return t("clusters.DFF9A.unknown");
			default:
				return "";
		}
	};

	const startSelfCheck = () => {
		setShowDialog(true);
		setSelfCheckStatus(Constants.SelfCheckStatus.Checking);
		const cmd = {
			action: "sendActionCmd",
			gatewayId: props.epDevice.gwId,
			srcGw: props.epDevice.srcGw,
			deviceId: props.epDevice.id,
			endpoint: props.epDevice.epId,
			caps: props.deviceType.cap,
			clusterId: props.deviceType.clusterId,
			cmdId: ClusterConstants.DFF9A.CmdIds.SelfCheck,
		} as const satisfies CmdSendActionCmd;
		send(cmd, (error, msg) => {
			if (!error && msg?.payload.status === "ok") {
				setShowGenericErrorMsg(undefined);
			} else {
				setShowGenericErrorMsg(Date.now());
			}
		});
	};

	const getItemRows = () => {
		const itemRows: Array<ReactNode> = [];

		if (selfCheckStatus !== null) {
			itemRows.push(
				<ListItem>
					<ListItemText primary={t("clusters.DFF9A.title")} />
					<ListItemContainer>
						<Button onClick={startSelfCheck}>
							{t("clusters.DFF9A.start")}
						</Button>
					</ListItemContainer>
				</ListItem>
			);
		}
		if (lelPercentage !== null) {
			itemRows.push(
				<ListItem>
					<ListItemText primary={props.deviceType.entries[ClusterConstants.DFF9A.Attributes.LELPercentage].getTitle()} />
					<ListItemText primary={props.deviceType.entries[ClusterConstants.DFF9A.Attributes.LELPercentage].formatValue(cluster)} />
				</ListItem>
			);
		}

		return itemRows;
	};

	if (props.showFull) {
		return (
			<>
				{getItemRows().map((itemRow, index) => (
					<Fragment key={index}>
						{(index > 0) && <Divider />}
						{itemRow}
					</Fragment>
				))}
				<DrawerDialog
					id="dlg-gas-sensor-self-check"
					title={t("clusters.DFF9A.dialogTitle")}
					open={showDialog}
					onClose={handleDialogClose}
					drawerActions={
						<Button
							className="btn-drawer-action-ok"
							variant="contained"
							onClick={handleDialogClose}
							sx={{ minWidth: "40%", maxWidth: "320px" }}
						>
							{t("dialog.ok")}
						</Button>
					}
					dialogActions={
						<Button
							className="btn-dlg-action-ok"
							variant="contained"
							disableElevation={true}
							onClick={handleDialogClose}
						>
							{t("dialog.ok")}
						</Button>
					}
				>
					<DialogContentText>{getSelfCheckStatusText(selfCheckStatus)}</DialogContentText>
				</DrawerDialog>
				<Toast
					autoHideDuration={6000}
					severity="error"
					open={showGenericErrorMsg}
					onClose={setShowGenericErrorMsg}
					message={t("toast.genericErrorMsg")}
				/>
			</>
		);
	}

	return null;
};

DFF9A.defaultProps = {
	showFull: false,
};

export default DFF9A;
