import { ListItem, ListItemText, Divider } from "@mui/material";
// services
import ClusterConstants from "../../services/cluster-constants";
// types
import type { EpDevice } from "../../types/device";
import type { DeviceType } from "../../types/device-type";

type Props = Readonly<{
	epDevice: EpDevice;
	deviceType: DeviceType<"FF9E">;
	showFull?: boolean;
}>;

const DFF9E = (props: Props) => {
	const cluster = props.epDevice.getClusterByCapAndClusterId(props.deviceType.cap, props.deviceType.clusterId)!;

	if (props.showFull) {
		return (
			<>
				<ListItem>
					<ListItemText primary={props.deviceType.entries[ClusterConstants.DFF9E.Attributes.SDCardStatus].getTitle()} />
					<ListItemText primary={props.deviceType.entries[ClusterConstants.DFF9E.Attributes.SDCardStatus].formatValue(cluster)} />
				</ListItem>
				<Divider />
				<ListItem>
					<ListItemText primary={props.deviceType.entries[ClusterConstants.DFF9E.Attributes.PowerAdapterStatus].getTitle()} />
					<ListItemText primary={props.deviceType.entries[ClusterConstants.DFF9E.Attributes.PowerAdapterStatus].formatValue(cluster)} />
				</ListItem>
			</>
		);
	}
	return null;
};

DFF9E.defaultProps = {
	showFull: false,
};

export default DFF9E;
