import { Fragment, useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import {
	Button,
	Select,
	MenuItem,
	List,
	ListItem,
	ListItemButton,
	ListItemText,
	Divider,
	Dialog,
	Slider,
	Collapse,
} from "@mui/material";
// cmp
import DrawerDialog from "../DrawerDialog";
import CenterCircularProgress from "../CenterCircularProgress";
import ListItemContainer from "../ListItemContainer";
import CustomSwitch from "../custom-switch";
import UnitDisplay from "./UnitDisplay";
import DelayDurationComponent from "../dialog-selectors/DelayDurationComponent";
import Toast from "../Toast";
// hooks
import useSend from "../../hooks/useSend";
import useDynamicUpdateState from "../../hooks/useDynamicUpdateState";
// services
import Constants from "../../services/constants";
import ClusterConstants from "../../services/cluster-constants";
import DeviceType from "../../services/device-type";
import { decimal2Hex } from "../../services/utils";
// types
import type { ReactNode } from "react";
import type { SelectChangeEvent } from "@mui/material/Select";
import type { DeviceType as DeviceTypeT } from "../../types/device-type";
import type { EpDevice } from "../../types/device";
import type { IncapsClusterFF9D, AttributeId } from "../../types/cluster";
import type { CmdSendGeneralCmdWrite } from "../../types/message";
import type { DataType, DataTypeMapper } from "../../types/misc";

type FSDTemperatureMode = (typeof Constants.FSDTemperatureModes)[number]["value"];
type LightAdjustMode = NonNullable<IncapsClusterFF9D[typeof ClusterConstants.DFF9D.Attributes.LightAdjust]>;
type GYDPirSensitivityMode = (typeof Constants.GYDPirSensitivityMode)[number]["value"];
type AmbientLightMode = (typeof Constants.AmbientLightMode)[number]["value"];

type Props = Readonly<{
	epDevice: EpDevice;
	deviceType: DeviceTypeT<"FF9D">;
	showFull: boolean;
}>;

const DFF9D = (props: Props) => {
	const send = useSend();

	const { t } = useTranslation();

	const cluster = props.epDevice.getClusterByCapAndClusterId(props.deviceType.cap, props.deviceType.clusterId);
	const gydDeviceMode = cluster?.[ClusterConstants.DFF9D.Attributes.GYDDeviceMode] ?? null;

	const timeoutIdRef = useRef<number | undefined>(undefined);

	const [loading, setLoading] = useState(false);
	const [showLightTypeDialog, setShowLightTypeDialog] = useState(false);
	const [showDuration, setShowDuration] = useState(false);
	const [showGenericErrorMsg, setShowGenericErrorMsg] = useState<number | undefined>(undefined);

	const [lightTypeValue, setLightTypeValue] = useDynamicUpdateState(cluster?.[ClusterConstants.DFF9D.Attributes.TouchDimmerSwitchLightType] ?? null);
	const [lightValue, setLightValue] = useDynamicUpdateState(cluster?.[ClusterConstants.DFF9D.Attributes.JSQLight] ?? null);
	const [diffuserValue, setDiffuserValue] = useDynamicUpdateState(cluster?.[ClusterConstants.DFF9D.Attributes.JSQSpray] ?? null);
	const [fsdValue, setFsdValue] = useDynamicUpdateState(cluster?.[ClusterConstants.DFF9D.Attributes.FSDCustomTempValue] ?? null);
	const [lightAdjustValue, setLightAdjustValue] = useDynamicUpdateState(cluster?.[ClusterConstants.DFF9D.Attributes.LightAdjust] ?? null);
	const [gydSwitchPir, setGydSwitchPir] = useDynamicUpdateState(cluster?.[ClusterConstants.DFF9D.Attributes.GYDSwitchPir] ?? null);
	const [gydPirSensitivity, setGydPirSensitivity] = useDynamicUpdateState(cluster?.[ClusterConstants.DFF9D.Attributes.GYDPirSensitivity] ?? null);
	const [gydCDS, setGydCDS] = useDynamicUpdateState(cluster?.[ClusterConstants.DFF9D.Attributes.GYDCDS] ?? null);
	const [gydPirDelay, setGydPirDelay] = useDynamicUpdateState(cluster?.[ClusterConstants.DFF9D.Attributes.GYDPirDelay] ?? null);

	useEffect(() => {
		setLoading(false);
	}, [gydDeviceMode]);

	useEffect(() => {
		if (loading) {
			timeoutIdRef.current = window.setTimeout(() => {
				setLoading(false);
			}, 10_000);
		} else {
			window.clearTimeout(timeoutIdRef.current);
		}
	}, [loading]);

	const closeDialog = () => {
		setShowLightTypeDialog(false);
	};

	const sendCmd = <DT extends DeviceTypeT<"FF9D">, DAT extends DataType>(deviceType: DT, attributeId: AttributeId<DT["cap"], DT["clusterId"]>, datatype: DAT, value: DataTypeMapper[DAT]) => {
		const cmd = {
			action: "sendGeneralCmd",
			gatewayId: props.epDevice.gwId,
			srcGw: props.epDevice.srcGw,
			deviceId: props.epDevice.id,
			endpoint: props.epDevice.epId,
			caps: deviceType.cap,
			clusterId: deviceType.clusterId,
			cmdId: Constants.GeneralCmdIds.WriteAttribute,
			values: [{
				id: attributeId,
				datatype: datatype,
				value: value,
			}],
		} as const satisfies CmdSendGeneralCmdWrite<"FF9D">;
		send(cmd, (error, msg) => {
			if (!error && msg?.payload.status === "ok") {
				setShowGenericErrorMsg(undefined);
			} else {
				setShowGenericErrorMsg(Date.now());
			}
		});
	};

	const handleLightTypeClick = (newLightTypeValue: typeof Constants.TouchDimmerSwitchLightTypes[number]["value"]) => {
		setShowLightTypeDialog(false);

		if (lightTypeValue !== newLightTypeValue) {
			setLightTypeValue(newLightTypeValue);
			sendCmd(DeviceType.DFF9D, ClusterConstants.DFF9D.Attributes.TouchDimmerSwitchLightType, Constants.DataType.Enum8Bit, decimal2Hex(newLightTypeValue, 2));
		}
	};

	const handleLightToggle = (isInputChecked: boolean) => {
		setLightValue(isInputChecked);
		sendCmd(DeviceType.DFF9D, ClusterConstants.DFF9D.Attributes.JSQLight, Constants.DataType.Boolean, isInputChecked);
	};

	const handleDiffuserToggle = (isInputChecked: boolean) => {
		setDiffuserValue(isInputChecked);
		sendCmd(DeviceType.DFF9D, ClusterConstants.DFF9D.Attributes.JSQSpray, Constants.DataType.Boolean, isInputChecked);
	};

	const handleTemperatureModeChange = (event: SelectChangeEvent<FSDTemperatureMode>) => {
		setFsdValue(event.target.value as FSDTemperatureMode);
		sendCmd(DeviceType.DFF9D, ClusterConstants.DFF9D.Attributes.FSDCustomTempValue, Constants.DataType.Enum8Bit, decimal2Hex(event.target.value as FSDTemperatureMode, 2));
	};

	const handleLightAdjustChange = (value: LightAdjustMode) => {
		sendCmd(DeviceType.DFF9D, ClusterConstants.DFF9D.Attributes.LightAdjust, Constants.DataType.Enum8Bit, decimal2Hex(value, 2));
	};

	const handleGydDeviceModeToggle = (isInputChecked: boolean) => {
		setLoading(true);
		sendCmd(DeviceType.DFF9D, ClusterConstants.DFF9D.Attributes.GYDDeviceMode, Constants.DataType.Enum8Bit, decimal2Hex(isInputChecked ? 1 : 0, 2));
	};

	const handleGydSwitchPirToggle = (isInputChecked: boolean) => {
		setGydSwitchPir(isInputChecked);
		sendCmd(DeviceType.DFF9D, ClusterConstants.DFF9D.Attributes.GYDSwitchPir, Constants.DataType.Boolean, isInputChecked);
	};

	const handleGydPirSensitivityChange = (event: SelectChangeEvent<GYDPirSensitivityMode>) => {
		setGydPirSensitivity(event.target.value as GYDPirSensitivityMode);
		sendCmd(DeviceType.DFF9D, ClusterConstants.DFF9D.Attributes.GYDPirSensitivity, Constants.DataType.Enum8Bit, decimal2Hex(event.target.value as GYDPirSensitivityMode, 2));
	};

	const handleGydCDSChange = (event: SelectChangeEvent<AmbientLightMode>) => {
		setGydCDS(event.target.value as AmbientLightMode);
		sendCmd(DeviceType.DFF9D, ClusterConstants.DFF9D.Attributes.GYDCDS, Constants.DataType.Enum8Bit, decimal2Hex(event.target.value as AmbientLightMode, 2));
	};

	const handleParamChange = (_event: null, value: number) => {
		setGydPirDelay(value);
		setShowDuration(false);
		sendCmd(DeviceType.DFF9D, ClusterConstants.DFF9D.Attributes.GYDPirDelay, Constants.DataType.UInt16Bit, decimal2Hex(value, 4));
	};

	if (props.showFull) {
		const entries: Array<ReactNode> = [];
		if (lightValue !== null) {
			entries.push(
				<ListItem>
					<ListItemText primary={t("clusters.DFF9D.light")} />
					<ListItemContainer><CustomSwitch checked={lightValue} onChange={handleLightToggle} /></ListItemContainer>
				</ListItem>
			);
		}
		if (diffuserValue !== null) {
			entries.push(
				<ListItem>
					<ListItemText primary={t("clusters.DFF9D.diffuser")} />
					<ListItemContainer><CustomSwitch checked={diffuserValue} onChange={handleDiffuserToggle} /></ListItemContainer>
				</ListItem>
			);
		}
		if (fsdValue !== null) {
			entries.push(
				<ListItem>
					<ListItemText primary={t("clusters.DFF9D.temperature")} />
					<ListItemContainer style={{ margin: "-7px 0" }}>
						<Select
							fullWidth={true}
							value={fsdValue}
							onChange={handleTemperatureModeChange}
						>
							{Constants.FSDTemperatureModes.map((fsdMode) => (
								<MenuItem key={fsdMode.value} value={fsdMode.value}>
									<ListItemText primary={t(fsdMode.l10n)} />
								</MenuItem>
							))}
						</Select>
					</ListItemContainer>
				</ListItem>
			);
		}
		if (lightAdjustValue !== null) {
			entries.push(
				<ListItem>
					<ListItemText primary={t("clusters.DFF9D.flameLevel")} />
					<ListItemContainer style={{ width: "25%" }}>
						<Slider
							marks={true}
							min={0}
							max={4}
							step={1}
							valueLabelDisplay="auto"
							valueLabelFormat={(value) => (value + 1)}
							value={lightAdjustValue}
							onChange={(event, value) => (setLightAdjustValue(value as LightAdjustMode))}
							onChangeCommitted={(event, value) => (handleLightAdjustChange(value as LightAdjustMode))}
							sx={{
								"& .MuiSlider-mark": {
									height: 8,
									width: 2,
								},
								"& .MuiSlider-markActive": {
									opacity: 1,
									backgroundColor: "currentColor",
								},
							}}
						/>
					</ListItemContainer>
				</ListItem>
			);
		}
		if (lightTypeValue !== null) {
			entries.push(
				<>
					<ListItemButton onClick={() => (setShowLightTypeDialog(true))}>
						<ListItemText primary={t("clusters.DFF9D.title")} />
						<ListItemText
							primary={t(Constants.TouchDimmerSwitchLightTypes.find((lightType) => (lightType.value === lightTypeValue))?.l10n ?? "")}
							primaryTypographyProps={{ sx: { color: "primary.main" } }}
						/>
					</ListItemButton>
					<DrawerDialog
						id="dlg-light-type"
						title={t("clusters.DFF9D.dialogTitle")}
						fullSizeDrawer={true}
						open={showLightTypeDialog}
						onClose={closeDialog}
						drawerActions={null}
						dialogActions={<Button className="btn-dlg-action-cancel" color="inherit" onClick={closeDialog}>{t("dialog.cancel")}</Button>}
					>
						<List disablePadding={true}>
							{Constants.TouchDimmerSwitchLightTypes.map((lightType) => (
								<ListItemButton
									className="li-light-type"
									data-light-type={lightType.value}
									key={lightType.value}
									sx={{ color: (lightType.value === lightTypeValue) ? "primary.main" : undefined }}
									onClick={() => (handleLightTypeClick(lightType.value))}
								>
									<ListItemText primary={t(lightType.l10n)} />
								</ListItemButton>
							))}
						</List>
					</DrawerDialog>
				</>
			);
		}
		if (gydDeviceMode !== null) {
			entries.push(
				<>
					<ListItem>
						<ListItemText primary={t("clusters.DFF9D.deviceModeAutomatic")} />
						<ListItemContainer><CustomSwitch checked={Boolean(gydDeviceMode)} onChange={handleGydDeviceModeToggle} /></ListItemContainer>
					</ListItem>
					<Collapse in={Boolean(gydDeviceMode)} timeout="auto">
						{(gydSwitchPir !== null) &&
							<>
								<Divider />
								<ListItem>
									<ListItemText primary={t("clusters.DFF9D.motionDetection")} />
									<ListItemContainer><CustomSwitch checked={gydSwitchPir} onChange={handleGydSwitchPirToggle} /></ListItemContainer>
								</ListItem>
							</>
						}
						{(gydPirSensitivity !== null) &&
							<>
								<Divider />
								<ListItem>
									<ListItemText primary={t("clusters.DFF9D.motionDistance")} />
									<ListItemContainer style={{ width: "20%" }}>
										<Select
											fullWidth={true}
											value={gydPirSensitivity}
											onChange={handleGydPirSensitivityChange}
										>
											{Constants.GYDPirSensitivityMode.map((mode) => (
												<MenuItem key={mode.value} value={mode.value}>
													<ListItemText primary={t(mode.l10n)} />
												</MenuItem>
											))}
										</Select>
									</ListItemContainer>
								</ListItem>
							</>
						}
						{(gydCDS !== null) &&
							<>
								<Divider />
								<ListItem>
									<ListItemText primary={t("clusters.DFF9D.ambientLight")} />
									<ListItemContainer style={{ width: "20%" }}>
										<Select
											fullWidth={true}
											value={gydCDS}
											onChange={handleGydCDSChange}
										>
											{Constants.AmbientLightMode.map((mode) => (
												<MenuItem key={mode.value} value={mode.value}>
													<ListItemText primary={t(mode.l10n)} />
												</MenuItem>
											))}
										</Select>
									</ListItemContainer>
								</ListItem>
							</>
						}
						{(gydPirDelay !== null) &&
							<>
								<Divider />
								<ListItemButton onClick={() => (setShowDuration(true))}>
									<ListItemText primary={t("clusters.DFF9D.timeDelay")} />
									<time dateTime={`PT${gydPirDelay}S`}>
										<UnitDisplay
											values={[gydPirDelay]}
											digits={0}
											unit={t("clusters.DFF9D.sec")}
										/>
									</time>
								</ListItemButton>
							</>
						}
						{(gydPirDelay !== null) &&
							<>
								<Divider />
								<DelayDurationComponent
									title={t("clusters.DFF9D.timeDelay")}
									open={showDuration}
									min={5}
									max={3600}
									value={gydPirDelay}
									onChange={handleParamChange}
									onCancel={() => (setShowDuration(false))}
								/>
							</>
						}
					</Collapse>
				</>
			);
		}

		return (
			<>
				{entries.map((entry, index) => (
					<Fragment key={index}>
						{(index > 0) && <Divider />}
						{entry}
					</Fragment>
				))}
				{loading && <Dialog id="dlg-device-loading" open={loading}><CenterCircularProgress /></Dialog>}
				<Toast
					autoHideDuration={6000}
					severity="error"
					open={showGenericErrorMsg}
					onClose={setShowGenericErrorMsg}
					message={t("toast.genericErrorMsg")}
				/>
			</>
		);
	}

	return null;
};

DFF9D.defaultProps = {
	showFull: false,
};

export default DFF9D;
