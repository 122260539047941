import { useState } from "react";
import { Link } from "react-router";
import { useTranslation } from "react-i18next";
import {
	Button,
	List,
	ListItemButton,
	ListItemIcon,
	ListItemText,
	Divider,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
// cmp
import DrawerDialog from "../DrawerDialog";
import ListItemContainer from "../ListItemContainer";
import TemperaturePicker from "../dialog-selectors/TemperaturePicker";
import UnitDisplay from "./UnitDisplay";
import Svg from "../svg";
import Toast from "../Toast";
// hooks
import useSend from "../../hooks/useSend";
import useDynamicUpdateState from "../../hooks/useDynamicUpdateState";
// services
import Gateway from "../../services/gateway";
import Constants from "../../services/constants";
import ClusterConstants from "../../services/cluster-constants";
import { roundToPrecision, convertToF, decimal2Hex } from "../../services/utils";
import { icons } from "@local/theme";
// types
import type { TempUnit } from "../../types/gateway";
import type { EpDevice } from "../../types/device";
import type { DeviceType } from "../../types/device-type";
import type { CmdSendGeneralCmdWrite } from "../../types/message";
import type { RelativePathPrefix } from "../../types/misc";

type MomitModeValue = typeof Constants.MomitModes[number]["value"];

type Props = Readonly<{
	epDevice: EpDevice;
	deviceType: DeviceType<"FF69">;
	relativePathPrefix: RelativePathPrefix;
	showFull?: boolean;
}>;

const MOMIT_MODES = Constants.MomitModes.filter((momitMode) => (momitMode.value !== 3));

const DFF69 = (props: Props) => {
	const send = useSend();

	const { t } = useTranslation();
	const theme = useTheme();

	const cluster = props.epDevice.getClusterByCapAndClusterId(props.deviceType.cap, props.deviceType.clusterId)!;

	const tempValue = props.deviceType.getValue(cluster);

	const [showModesDialog, setShowModesDialog] = useState(false);
	const [showTempDialog, setShowTempDialog] = useState(false);

	const [modeValue, setModeValue] = useDynamicUpdateState(cluster[ClusterConstants.DFF69.Attributes.ST_State]);
	const [tempSetPoint, setTempSetPoint] = useDynamicUpdateState(roundToPrecision(cluster[ClusterConstants.DFF69.Attributes.Setpoint] / 100));
	const [showGenericErrorMsg, setShowGenericErrorMsg] = useState<number | undefined>(undefined);

	const openTempDialog = () => {
		setShowTempDialog(true);
	};

	const closeModesDialog = () => {
		setShowModesDialog(false);
	};

	const handleModeClick = (momitModeValue: MomitModeValue) => {
		if (momitModeValue === modeValue) {
			setShowModesDialog(false);
		} else {
			const cmd = {
				action: "sendGeneralCmd",
				gatewayId: props.epDevice.gwId,
				srcGw: props.epDevice.srcGw,
				deviceId: props.epDevice.id,
				endpoint: props.epDevice.epId,
				caps: props.deviceType.cap,
				clusterId: props.deviceType.clusterId,
				cmdId: Constants.GeneralCmdIds.WriteAttribute,
				values: [{
					id: ClusterConstants.DFF69.Attributes.ST_State,
					datatype: Constants.DataType.UInt8Bit,
					value: decimal2Hex(momitModeValue, 2),
				}],
			} as const satisfies CmdSendGeneralCmdWrite<"FF69">;
			send(cmd, (error, msg) => {
				if (!error && msg?.payload.status === "ok") {
					setModeValue(momitModeValue);
					setShowGenericErrorMsg(undefined);
				} else {
					setShowGenericErrorMsg(Date.now());
				}
				setShowModesDialog(false);
			});
		}
	};

	const handleTempChange = (e: null, temp: number) => {
		if (temp === tempSetPoint) {
			setShowTempDialog(false);
		} else {
			const cmd = {
				action: "sendGeneralCmd",
				gatewayId: props.epDevice.gwId,
				srcGw: props.epDevice.srcGw,
				deviceId: props.epDevice.id,
				endpoint: props.epDevice.epId,
				caps: props.deviceType.cap,
				clusterId: props.deviceType.clusterId,
				cmdId: Constants.GeneralCmdIds.WriteAttribute,
				values: [{
					id: ClusterConstants.DFF69.Attributes.Setpoint,
					datatype: Constants.DataType.UInt16Bit,
					value: decimal2Hex(Math.round(temp * 100), 4),
				}],
			} as const satisfies CmdSendGeneralCmdWrite<"FF69">;
			send(cmd, (error, msg) => {
				if (!error && msg?.payload.status === "ok") {
					setTempSetPoint(roundToPrecision(temp));
					setShowGenericErrorMsg(undefined);
				} else {
					setShowGenericErrorMsg(Date.now());
				}
				setShowTempDialog(false);
			});
		}
	};

	const getRange = (tempUnit: TempUnit) => {
		if (tempUnit === Constants.TempUnit.Fahrenheit) {
			return {
				min: 50,
				max: 86,
			} as const;
		}
		return {
			min: 10,
			max: 30,
		} as const;
	};

	if (!props.showFull && !tempSetPoint) {
		return null;
	}

	const gatewayTempUnit = Gateway.selectedGatewayTemperature;
	const tempSetPointValue = (gatewayTempUnit === Constants.TempUnit.Fahrenheit) ? convertToF(tempSetPoint) : roundToPrecision(tempSetPoint);

	const thermoMode = Constants.MomitModes.find((momitMode) => (momitMode.value === modeValue));

	const range = getRange(gatewayTempUnit);

	return (
		<>
			{props.showFull ? (
				<>
					{(thermoMode !== undefined) &&
						<ListItemButton onClick={() => (setShowModesDialog(true))}>
							<ListItemIcon><Svg src={props.deviceType.getIcon()} /></ListItemIcon>
							<ListItemText primary={t("clusters.DFF69.thermo")} />
							<ListItemText primary={t(thermoMode.l10n)} sx={{ color: "primary.main" }} />
						</ListItemButton>
					}
					{Boolean(tempSetPoint) && <Divider />}
					{Boolean(tempSetPoint) &&
						<ListItemButton disabled={modeValue === 0 || modeValue === 2} onClick={openTempDialog}>
							<ListItemText primary={t("clusters.DFF69.tempSetPoint")} />
							<ListItemContainer>
								<UnitDisplay
									values={[tempSetPointValue]}
									digits={props.deviceType.getDigits()}
									unit={props.deviceType.getUnit()}
									style={{ color: (modeValue === 0 || modeValue === 2) ? theme.palette.text.disabled : theme.palette.primary.main }}
								/>
							</ListItemContainer>
						</ListItemButton>
					}
					{Boolean(tempValue) && <Divider />}
					{Boolean(tempValue) &&
						<ListItemButton component={Link} to={`${props.relativePathPrefix}graph/${props.deviceType.clusterId}/${props.deviceType.attributeId}`}>
							<ListItemText primary={t("clusters.DFF69.temp")} />
							<ListItemContainer>
								<UnitDisplay
									values={[tempValue]}
									digits={props.deviceType.getDigits()}
									unit={props.deviceType.getUnit()}
								/>
							</ListItemContainer>
							<ListItemIcon><icons.ChevronRight /></ListItemIcon>
						</ListItemButton>
					}
				</>
			) : (
				<Button className="button-FF69-setpoint" disabled={modeValue === 0 || modeValue === 2} onClick={openTempDialog}>
					<UnitDisplay
						values={[tempSetPointValue]}
						digits={props.deviceType.getDigits()}
						unit={props.deviceType.getUnit()}
						style={{ color: (modeValue === 0 || modeValue === 2) ? theme.palette.text.disabled : theme.palette.primary.main }}
					/>
				</Button>
			)}
			<DrawerDialog
				id="dlg-thermostat-mode-momit"
				title={t("clusters.DFF69.thermoDialogTitle")}
				open={showModesDialog}
				onClose={closeModesDialog}
				drawerActions={null}
				dialogActions={<Button className="btn-dlg-action-cancel" color="inherit" onClick={closeModesDialog}>{t("dialog.cancel")}</Button>}
			>
				<List disablePadding={true}>
					{MOMIT_MODES.map((momitMode) => (
						<ListItemButton
							className="li-thermostat-mode"
							data-thermostat-mode={momitMode.value}
							key={momitMode.value}
							sx={{ color: (momitMode.value === modeValue) ? "primary.main" : undefined }}
							onClick={() => (handleModeClick(momitMode.value))}
						>
							<ListItemText primary={t(momitMode.l10n)} />
						</ListItemButton>
					))}
				</List>
			</DrawerDialog>
			{showTempDialog &&
				<TemperaturePicker
					title={t("clusters.DFF69.tempDialogTitle")}
					open={showTempDialog}
					value={tempSetPoint}
					min={range.min}
					max={range.max}
					unit={gatewayTempUnit}
					okText={t("general.set")}
					onChange={handleTempChange}
					onCancel={() => (setShowTempDialog(false))}
				/>
			}
			<Toast
				autoHideDuration={6000}
				severity="error"
				open={showGenericErrorMsg}
				onClose={setShowGenericErrorMsg}
				message={t("toast.genericErrorMsg")}
			/>
		</>
	);
};

DFF69.defaultProps = {
	showFull: false,
};

export default DFF69;
