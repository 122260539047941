import { useTranslation } from "react-i18next";
import { ListItem, ListItemText } from "@mui/material";
// types
import type { EpDevice } from "../../types/device";
import type { DeviceType } from "../../types/device-type";

type Props = Readonly<{
	epDevice: EpDevice;
	deviceType: DeviceType<"FF71">;
}>;

const DFF71 = (props: Props) => {
	const { t } = useTranslation();

	const cluster = props.epDevice.getClusterByCapAndClusterId(props.deviceType.cap, props.deviceType.clusterId)!;

	return (
		<ListItem>
			<ListItemText primary={t("clusters.DFF71.notification")} />
			<ListItemText primary={props.deviceType.formatValue(cluster) ?? t("clusters.DFF71.noNotification")} />
		</ListItem>
	);
};

export default DFF71;
